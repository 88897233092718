.subtitles {
  padding-bottom: 4em;
}

.subtitles-single p {
  margin: 0;
}

.subtitles-single {
  margin-top: 1em;
}

.hidden {
  display: none;
}

a, a:visited {
  color: inherit;
}

.episode-card {
  padding: 1em;
  background-color: khaki;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 4px 4px gray;
}

.episode-card a {
  display: flex;
  flex-direction: column;
  justify-items: center;
}

.episode-card a h3 {
  margin: 0;
}

.episode-card a img {
  max-width: 300px;
  width: 100%;
  margin: auto;
}

body {
  max-width: 100vw;
  padding: 1em;
}

.appnav a {
  padding: 1em;
  width: 2em;
  display: flex;
  justify-content: center;
  transition: width 100ms linear, padding 100ms linear;
}

.appnav a.hidden {
  display: flex !important;
  visibility: hidden;
  width: 0;
  padding: 0;
}

.appnav {
  position: fixed;
  bottom: 1em;
  right: 1em;
  border-radius: 10px;
  background-color: honeydew;
  display: flex;
  box-shadow: 4px 4px gray;
}

@media only screen and (min-width: 768px) {
  .appnav {
    top: 1em;
    left: 1em;
    right: unset;
    bottom: unset;
  }

  .subtitles {
    margin-top: 4em;
  }

  .season {
    display: flex;
    flex-direction: column;
    margin-bottom: 1em;
    padding: 1em;
    overflow: hidden;
  }

  .episode-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .episode-card {
    max-width: 25em;
    min-width: 300px;
    padding: 1em;
    margin-right: 10px;
  }
}